<template>
  <div class="modal-logout">
      <base-title size="l" mB="xl">Logout</base-title>
      <base-text mB="xl">Are you sure</base-text>
      <base-button @click="logout">Logout</base-button>
  </div>
</template>

<script>
export default {
  name: "modalLogout",

  methods: {
    logout() {
      this.$api.logout()
      this.$emit('closeModal')
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-logout {
}
</style>
